<template>
  <div style="background: white">
    <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="onClickLeft">
    </van-nav-bar>

    <div v-if="activity">

      <!--banner-->
      <img v-if="activity.configObj && activity.configObj.showTopBanner" class="ppt-page" :src="activity.bannerUrl"/>


      <!--活动内容-->
      <div>
        <div class="section-title" v-show="activity.contentType && activity.contentType !=='outurl'" style="padding: 16px">
          <div class="row-title">活动内容</div>
          <div v-html="activity.content"></div>
        </div>

        <div v-if="activity.contentType==='imgurl'">
          <img class="ppt-page" :src="activity.content"/>
        </div>
        <iframe v-if="activity.contentType==='url'" :src="activity.content" style="width: 100%; height: 400px; "
                frameborder="0"></iframe>
      </div>

      <!--活动说明-->
      <div v-if="activity.description">
        <div class="section-title" v-show="activity" style="padding: 16px">
          <div class="row-title">活动说明</div>
          <div v-html="activity.description"></div>
        </div>

        <div v-if="activity.descType==='imgurl'">
          <img class="ppt-page" :src="activity.description"/>
        </div>
        <iframe v-if="activity.descType==='url'" :src="activity.description" style="width: 100%; height: 400px; "
                frameborder="0"></iframe>

      </div>


      <!--location-->
      <div v-if="activity.location || this.showMap" style="margin-top: 16px; padding: 16px">
        <div class="row-title">活动地点</div>
        <div v-if="activity.location">{{ activity.location }}</div>

        <div id="map-wrapper" ref="mapWrapper" class="contents" style="height: 260px; margin-top: 16px" v-if="showMap">
          <div id="mymap" class="map" ref="mymap">
          </div>
        </div>
      </div>

    </div>


  </div>

</template>

<script>


import Vue from 'vue';
import {NavBar} from 'vant';

Vue.use(NavBar);
import {Cell, CellGroup, Col, Icon, PullRefresh, Row, Tabbar, TabbarItem} from 'vant';
import {mapActions, mapState} from "vuex";
import * as _ from 'lodash';
import mapUtils from "@/utils/mapUtils";

Vue.use(PullRefresh);
Vue.use(Icon);

Vue.use(Cell).use(CellGroup);

Vue.use(Tabbar).use(TabbarItem);

Vue.use(Row).use(Col);

export default {
  name: "index",
  components: {},
  props: ['id'],
  data() {
    return {
      activity: {},
      showImg: false,
      imgUrl: '',
      showMap: false,
      cpoint: [],//中心点
      map: null,
      choiseMapData: null,
      searchWord: null,
      searchData: null,
      currLocation: null,
      currPosition: null,
      geolocation: null,
      navTitle: null,
    }
  },
  created() {
  },

  mounted() {
    this.activity = this.activityList.find(i => i.id == this.id) || {}
    this.loadData();

  },

  watch: {
    'activityList': {
      handler(v) {
        if (v) {
          this.activity = v.find(i => i.id == this.id)
        }
      }
    },
    activity: {
      handler(val) {
        console.log('watch activity', val, this.$refs);

        if (val) {

          if (val.title) {
            document.title = val.title
            this.navTitle = val.title
          }

          if (val.config) {
            try {
              val.configObj = JSON.parse(val.config) || {}
            } catch (e) {

            }
          }

          if (val.contentType === 'html') {
            if (this.$refs.contentWrapper) {
              this.$refs.contentWrapper.innerHTML = val.content || '<div>无内容</div>';
            }

          } else if (val.contentType === 'imgurl') {
            this.showImg = true;
            this.imgUrl = val.content
          } else if (val.contentType === 'url') {

          }else if (val.contentType === 'outurl') {
            //外部活动链跳转
            window.location.href = val.content;
          }

          if (val.description) {

            if (/^[ \t]*<div/.test(val.description)) {
              // html content
              val.descType = 'html'
            } else if (/^http(s?):\/\/.*\.(?:jpg|jpeg|png)/i.test(val.description)) {
              val.descType = 'imgurl'
            } else if (/^http(s?):\/\/.*/i.test(val.description)) {
              val.descType = 'url'
            } else {
              val.descType = 'text'
            }
          } else {
            val.descType = 'text'
          }

          if (val.locationCoords) {
            this.showMap = true;
            this.$nextTick(() => {
              this.initMap()
              this.showLocationListOnMap(val)
            })
          }
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      activityList: state => state.taxiActivities
    })
  },

  methods: {
    ...mapActions({
      loadTaxiActivities: 'loadTaxiActivities'
    }),
    initMap() {
      if (!this.showMap) {
        return;
      }
      if (this.map) {
        return;
      }

      let options = {
        resizeEnable: true,
        center: [108.317125, 22.818672],
        zoom: 12,
        mapStyle: 'amap://styles/16fe97ded05d5c2e459a7b73cf7fad73'
      };

      // console.log('map options = ', options);
      this.map = new AMap.Map("mymap", options);

      // this.map.setFeatures("road");  // 单一种类要素显示
      this.map.setFeatures(['road', 'point', 'bg', 'building']); // 多个种类要素显示

      this.map.on('touchend', () => {
        let location = this.map.getCenter();
        this.cpoint = [location.getLng(), location.getLat()];
        this.geocoder(this.cpoint);
      });

      // 插件使用说明：
      // https://developer.amap.com/api/javascript-api/guide/abc/plugins#plugin
      // https://developer.amap.com/api/javascript-api/reference/map-control#AMap.ToolBar
      this.map.plugin(["AMap.ToolBar"], () => {
        //加载工具条
        var tool = new AMap.ToolBar({locate: true, position: 'LB', liteStyle: true});
        this.map.addControl(tool);
      })

      this.getLocationFromMap();
      this.currLocation = new AMap.LngLat(108.266228, 22.862768); // 初始为国电智能大厦

    },
    getLocationFromMap() {
      if (!this.map) {
        return;
      }

      // GeolocationResult
      let onComplete = (result) => {
        // https://lbs.amap.com/api/javascript-api/reference/location#m_GeolocationResult

        if (result) {
          // this.currPosition = result;
          // this.currLocation = new AMap.LngLat(result.position.getLng(), result.position.getLat());
          this.currLocation = result;
          console.log('定位成功，当前位置', this.currLocation, result);
          // this.currLocationAddress = this.currPosition.formattedAddress
        }
      };

      // GeolocationError
      let onError = (error) => {
        // console.log('定位错误', error);
      };

      this.map.plugin('AMap.Geolocation', () => {
        if (this.geolocation == null) {
          let geolocation = new AMap.Geolocation({
            enableHighAccuracy: true,//是否使用高精度定位，默认:true
            timeout: 10000,          //超过10秒后停止定位，默认：无穷大
            maximumAge: 0,           //定位结果缓存0毫秒，默认：0
            convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
            showButton: false,        //显示定位按钮，默认：true
            buttonPosition: 'LB',    //定位按钮停靠位置，默认：'LB'，左下角
            buttonOffset: new AMap.Pixel(10, 140),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
            showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
            panToLocation: false,     //定位成功后将定位到的位置作为地图中心点，默认：true
            zoomToAccuracy: false      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          });

          this.map.addControl(geolocation);
          this.map.on('complete', onComplete)

          // AMap.Event.addListener(geolocation, 'complete', onComplete);//返回定位信息
          // AMap.Event.addListener(geolocation, 'error', onError);      //返回定位出错信息

          geolocation.getCurrentPosition();
          this.geolocation = geolocation;
        } else {
          this.geolocation.getCurrentPosition();
        }
      });
    },
    async loadData() {
      if (this.lo.isEmpty(this.activityList)) {
        try {
          let d = await this.loadTaxiActivities();
        } catch (e) {
          console.log(e);
        }
      }
    },
    onClickLeft() {
      this.$store.dispatch('popRouterPath');
    },
    //地理解析
    geocoder(lnglat) {

      let Geocoder = new AMap.Geocoder({extensions: 'all'});
      Geocoder.getAddress(lnglat, (status, result) => {
        console.log('逆地理解析', result);

        if (status == 'complete') {

          let fmtAddr = result.regeocode.formattedAddress.replace('广西壮族自治区南宁市', '');
          fmtAddr = fmtAddr.replace('广西壮族自治区', '');
          this.searchWord = fmtAddr;
          this.choiseMapData = {
            address: fmtAddr,
            lng: lnglat[0],
            lat: lnglat[1],
          }

          this.searchData = [];
          result.regeocode.pois.forEach(item => {
            let obj = {
              title: item.name,
              address: item.address,
              lng: item.location.lng,
              lat: item.location.lat,
            }
            this.searchData.push(obj);
          })
        }
      })
    },

    async showLocationListOnMap(activity) {

      let amapLLArr = await mapUtils.locationTextToArray(activity.locationCoords, activity.coordinate)
      activity.amapLocationList = amapLLArr

      // 将坐标转成地址，然后一起返回
      let locWithAddrItems = await Promise.all(amapLLArr.map(async point => {
        const addr = await mapUtils.reverseLocation(point, '南宁')
        return {
          ll: point,
          addr,
          fmtAddr: addr.regeocode.formattedAddress.replace('广西壮族自治区南宁市', '')
        }
      }))
      console.log('locWithAddrItems', locWithAddrItems)
      this.showMarkersOnMap(locWithAddrItems)
    },

    showMarkersOnMap(latLatArray) {

      let showOptions = {
        getMarkerIcon(item) {
          const iconImg = require('@/assets/img/location_active.png')
          return new AMap.Icon({
            size: new AMap.Size(32, 32), // 图标尺寸
            image: iconImg, // Icon 的图像
            imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new AMap.Size(32, 32) // 根据所设置的大小拉伸或压缩图片
          })
        },
        keyName: 'key',
        getPosition(item) {
          return item.ll;
        },
        label: true,
        getLabel(item) {
          return item.fmtAddr
        },
        cachedMarkers: [],
        map: this.map
      }

      latLatArray.forEach(amapLL => {
        let icon = showOptions.getMarkerIcon(amapLL)
        // https://lbs.amap.com/api/javascript-api/reference/overlay#marker
        const marker = new AMap.Marker({
          position: showOptions.getPosition(amapLL),
          offset: new AMap.Pixel(0, 0),
          icon: icon,
          anchor: 'center',
          clickable: true,
          topWhenClick: true
        })

        if (showOptions.label) {
          marker.setLabel({
            content: `<div class='marker-label-info'>${showOptions.getLabel(amapLL)}</div>`,
            offset: new AMap.Pixel(0, 0),
            direction: 'right'
          })
        }

        console.log('marker', marker);
        this.map.add(marker)
        if (_.isArray(showOptions.cachedMarkers)) {
          showOptions.cachedMarkers.push(marker)
        } else if (_.isMap(showOptions.cachedMarkers)) {
          let k = _.get(amapLL, showOptions.keyName)
          showOptions.cachedMarkers[k] = marker
        }

      })


      if (_.isArray(showOptions.cachedMarkers)) {
        // https://lbs.amap.com/api/javascript-api/reference/map
        // 根据地图上添加的覆盖物分布情况，自动缩放地图到合适的视野级别，参数均可缺省。
        // overlayList为覆盖物数组，缺省时为当前地图上添加的所有覆盖物图层，
        // immediately代表是否需要动画过程，
        // avoid代表上下左右的像素避让宽度，
        // maxZoom代表fitView之后的最大级别
        this.map.setFitView(showOptions.cachedMarkers, true, [32, 32, 32, 32])
      } else if (_.isMap(showOptions.cachedMarkers)) {
        this.map.setFitView(_.transform(showOptions.cachedMarkers, function (result, value, key) {
          result.push(value);
        }, []), true, [32, 32, 32, 32]);
      }
    },
  },

}
</script>

<style scoped>

.map {
  height: 100%;
  width: 100%;
}

.content {
  flex-direction: column;
}

.ppt-page {
  /*pointer-events:none;!* 禁止长按图片保存 *!*/
  padding-top: 0;
  padding-bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  alignment: center;
  width: 100%;
  max-width: 660px;
}

.ppt-page.qr {
  /*pointer-events:auto;*/
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

.siteTr:nth-of-type(odd) {
  background: #ffffff;
}

.siteTr:nth-of-type(even) {
  background: #f5f5f5;
}

.siteTr:nth-of-type(1) {
  background: #222222;
  color: gold;
}


.siteTr:nth-of-type(1) td {
  background: #222222;
  color: gold;
}

.siteTr td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.row-title {
  font-weight: bold;
  margin-bottom: 16px;
  color: darkorange;
}
</style>
