import * as _ from 'lodash';
import gcoord from 'gcoord'
export default {


    /**
     * 转换指定坐标为高德坐标
     * @param resultArray
     * @param inputArray LngLat 数组或者 LngLat 实例
     * @param currIndex
     * @param endIndex
     * @param coordName 坐标系名称，'gps'，'baidu'
     * @param successCallback
     * @param errCallback
     */
    recursiveConvertToAMapCoord(resultArray, inputArray, currIndex, endIndex, coordName, successCallback, errCallback) {
        // console.log('------');
        // console.log('- amap 转换坐标', '坐标总数', inputArray.length, '个，从第', currIndex,'个转换到第', endIndex, '个(不包含)');

        // if (lineArr.length - currIndex > 40) {
        // endIndex = currIndex + 40; // not include endIndex element
        var tempInputArray = inputArray.slice(currIndex, endIndex)

        // https://lbs.amap.com/api/jsapi-v2/guide/transform/convertfrom
        AMap.convertFrom(tempInputArray, coordName, (status, result) => {
            if (result.info === 'ok') {
                result.locations.forEach(function (loc) {
                    resultArray.push(loc)
                })

                // console.log(`√amap坐标系转换成功, ${currIndex} ${endIndex}`, '转换了', result.locations.length, '(result.locations.length)个坐标')

                if (endIndex >= inputArray.length) {
                    // console.log('结束转换：', currIndex, endIndex);

                    if (typeof successCallback === 'function') {
                        successCallback(resultArray)
                    }
                } else {
                    // 81
                    // 0 - 40
                    // 40 - 80
                    const newEndIndex = (endIndex + 40) < inputArray.length ? endIndex + 40 : inputArray.length
                    this.recursiveConvertToAMapCoord(resultArray, inputArray, endIndex, newEndIndex, coordName, successCallback, errCallback)
                }
            } else {
                if (typeof errCallback === 'function') {
                    console.error('convertFrom with error ', result)
                    errCallback(result.info)
                }
                console.warn('坐标转换失败, status= ', status, 'result=', result)
            }
        })
    },

    /**
     * inCoordName
     * 地球上同一个地理位置的经纬度，在不同的坐标系中，会有少于偏移，国内目前常见的坐标系主要分为三种：

     地球坐标系——WGS84：常见于 GPS 设备，Google 地图等国际标准的坐标体系。
     火星坐标系——GCJ-02：中国国内使用的被强制加密后的坐标体系，高德坐标就属于该种坐标体系。
     百度坐标系——BD-09：百度地图所使用的坐标体系，是在火星坐标系的基础上又进行了一次加密处理。
     */
    convertGpsToAMapCoord(inCoords, inCoordName, successCallback, errCallback) {
        const resultArray = []
        this.recursiveConvertToAMapCoord(resultArray, inCoords, 0, inCoords.length > 40 ? 40 : inCoords.length, inCoordName, successCallback, errCallback)
        return resultArray
    },

    convertFrom(inCoords, fromCoordName) {
        return new Promise((resolve, reject) => {
            if (!_.isArray(inCoords)) {
                reject('输入坐标不是数组')
                return
            }
            if (_.isEmpty(inCoords)) {
                reject('输入坐标为空')
                return
            }
            // TODO: type 检查
            const resultArray = []
            try {
                this.recursiveConvertToAMapCoord(resultArray,
                    inCoords, 0,
                    inCoords.length > 40 ? 40 : inCoords.length, fromCoordName,
                    (resultArray) => {
                        // console.log('坐标转换结果数组：', resultArray);
                        resolve(resultArray)
                    }, (reason) => {
                        reject(reason)
                    })
            } catch (e) {
                reject(e)
            }
        })
    },

    reverseLocation(amapLL, city) {
        return new Promise((resolve, reject) => {
            //https://lbs.amap.com/api/javascript-api/reference/lnglat-to-address#m_AMap.Geocoder
            AMap.plugin('AMap.Geocoder', () => {
                var geocoder
                const regeoCode = () => {
                    if (!geocoder) {
                        geocoder = new AMap.Geocoder({
                            // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                            city: city || '南宁',
                            radius: 1000 // 范围，默认：500
                        })
                    }
                    geocoder.getAddress(amapLL,
                        function (status, result) {
                            if (status === 'complete' && result.regeocode) {
                                resolve(result)
                            } else {
                                reject('逆地理解析位置失败')
                            }
                        })
                }

                regeoCode()
            })
        })
    },

    /**
     * 将分号分割的lng,lat对转成
     * @param separatorLocationText lng,lat;lng,lat...
     * @param fromCoord baidu,amap,gps
     * @returns {Promise<unknown>}
     */
    async locationTextToArray(separatorLocationText, fromCoord) {
        if (!separatorLocationText) {
            return Promise.reject('Empty location text');
        }
        let coordArr = separatorLocationText.split(';');
        if (_.isEmpty(coordArr)) {
            return Promise.reject('Invalid or empty location text')
        }
        let locations = coordArr.map(coord => new AMap.LngLat(coord.split(',')[0], coord.split(',')[1]));

        return this.convertFrom(locations, fromCoord)
    },
    gaoDeToBaidu(lngLat) {
        return gcoord.transform(lngLat, gcoord.GCJ02, gcoord.BD09)
    },
    baiDuToGaoDe(lngLat) {
        return gcoord.transform(lngLat, gcoord.BD09, gcoord.GCJ02);
    },
}
